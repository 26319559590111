<template>
  <v-container class=" pa-0">
    <v-row class="ma-0 ">
      <v-col
        cols="12"
      >
        <v-card flat>
          <v-toolbar
            dense
            elevation="0"
            height="50"
            color="blue"
          >
            <h2 class="white--text">
              {{ titulo }}
            </h2>
            <v-spacer />

            <v-btn
              align="right"
              color="info"
              dark
              small
              class="ma-1"
              @click="regresar"
            >
              <v-icon>mdi-reply</v-icon>
            </v-btn>

            <v-btn
              align="right"
              color="success"
              dark
              small
              class="ma-1"
              @click.prevent="agregar"
            >
              <v-icon class="mr-1">
                mdi-check-circle
              </v-icon> PROCESAR
            </v-btn>
          </v-toolbar>

          <v-divider class="mb-3" />

          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
          >
            <v-row class="mx-3">
              <v-col
                cols="12"
                md="8"
                sm="8"
              >
                <v-row>
                  <v-col
                    cols="12"
                    md="6"
                    lg="6"
                  >
                    <v-menu
                      ref="menu1"
                      v-model="menu1"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      safely
                      transition="scale-transition"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="fecha"
                          filled
                          dense
                          rounded
                          hide-details
                       
                          label="Fecha de la cita"
                          persistent-hint
                          prepend-inner-icon="event"
                          min="2021-05-24"
                          v-on="on"
                        />
                      </template>
                    </v-menu>
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                    lg="6"
                  >
                    <v-menu
                      ref="menu"
                      v-model="menu2"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      :return-value.sync="time"
                      max-width="290px"
                      min-width="290px"
                      transition="scale-transition"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="time"
                          filled
                          rounded
                          hide-details
                          label="Hora"
                          prepend-inner-icon="access_time"
                          v-on="on"
                        />
                      </template>
                      <v-time-picker
                        v-if="menu2"
                        v-model="time"
                        full-width
                        @click:minute="$refs.menu.save(time)"
                      />
                    </v-menu>
                  </v-col>
                </v-row>
      
                <v-row>
                  <v-col
                    cols="12"
                    md="6"
                    lg="6"
                  >
                    <v-card
                      flat
                      elevation="0"
                    >
                      <v-text-field
                        v-model="lugar"
                        hide-details
                        label="Lugar"
                        prepend-icon="place"
                      />

                      <v-text-field
                        v-model="numproy"
                        hide-details
                        prepend-icon="notes"
                        label="Proyecto"
                      />
                    
                      <v-text-field
                        v-model="asunto"
                        prepend-icon="mdi-account"
                        label="Asunto"
                      />

                      <v-textarea
                        v-model="temas"
                        class="ma-3"
                        outlined
                        name="input-7-4"
                        label="Temas a tratar"
                        rows="2"
                        hide-details
                        auto-grow
                      />
                    </v-card>
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                    lg="6"
                  >
                    <v-card
                      class="rounded-lg"
                      elevation="1"
                      color="blue-grey lighten-5"
                    >
                      <!-- <v-row justify="center" class="mt">
                        <h2>Contacto</h2>
                    </v-row>   -->
                      <!-- CONTACTOS -->
                      <v-row justify="center">
                        <h3 class="mt-2">
                          Contacto
                        </h3>
                      </v-row>
                      <leecontacto />

                      <v-text-field
                        v-model="empresa"
                        dense
                        class="ml-5 mx-3"
                        prepend-icon="work"
                        label="Empresa"
                        hide-details
                      />
                      <v-text-field
                        v-model="email"
                        prepend-icon="mdi-at"
                        hide-details
                        class="ml-5 mx-3"
                        label="Email"
                      />
                      <v-text-field
                        v-model="telefono"
                        prepend-icon="mdi-phone"
                        class="ml-5 mx-3"
                        label="Telefono"
                        hide-details
                      />
                      <v-text-field
                        v-model="celular"
                        prepend-icon="mdi-cellphone-iphone"
                        class="ml-5 mx-3"
                        label="Celular"
                        hide-details
                      />
                      <leecliente />
                    </v-card>
                  </v-col>
                </v-row>
              </v-col>
              <v-col
                cols="12"
                md="4"
                sm="4"
              >
                <v-date-picker
                  v-model="fecha"
                  full-width
                  locale="es"
                  elevation="1"
                  header-color="blue"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
    <v-breadcrumbs
      :items="items"
      large
      class="mt-7"
    />
    <v-toolbar
      color="blue"
      dense
    />
  </v-container>
</template>

<script>
import leecontacto from '@/components/leecontacto'
import leecliente from '@/components/leecliente'
import {mapGetters, mapActions} from 'vuex'

export default{
  components:{
    leecontacto,
    leecliente
  },
 

  data(){
    return {

      items: [
        {
          text: 'Panel',
          disabled: false,
          href: 'panel',
          to: 'panel'
        },
        {
          text: 'Catalgo de citas',
          disabled: false,
          href: 'catcitas',
          to: 'catcitas'
        },
        {
          text: 'Nueva cita',
          disabled: true,
          href: 'breadcrumbs_link_2',
          to: 'panel'
        },
      ],

      picker: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),

      valid: true,

      duracion: ['30','60','90','120'],
      seledura: '30',
      snackbar: false,
      time: this.hora(),
      
      time2:null,
      detalle:'',
      titulo:'',
      menu2: false,
      menu3: false,

      fecha: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      date1: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    

      menu1: false,
      minutos:30,
      asunto:'',
      numcont:'',
      empresa:'',
      lugar:'',

      temas:'',
      numproy:'',
      idcontacto: 0,
      idcita:'',
      nModo: 1,

      email:'',
      telefono:'',
      celular:'',

    }
  },
	
  computed:{
    ...mapGetters('contactos', ['getContacto', 'getNewContacto']),
    ...mapGetters('clientes', ['getCliente']),
    ...mapGetters('login',['getdatosUsuario']),

    validaInfo(){

      this.getNewContacto
      let lvalida = this.validate()
      console.log("lvalida", lvalida)
      console.log("nomcont", this.nomcont)
      if (lvalida == false) {
        return
      }
   
      return  this.asunto   !== ''  &&
		  this.fechastart !== '' &&
		  this.hora !== '' &&
    	this.temas  !== '' &&
      this.nomcont !== ''
    },
  },

  watch: {
    getContacto(){
      // this.limpiar()
      this.actualizarDatoscontactos()
    },

    getNewContacto(){
      this.actualizarNomcont()
    }
  },

  created(){
    console.log('router', this.$route.params.cita )

    let ruta = this.$route.params.cita

    this.idusuariosweb = parseInt(this.getdatosUsuario.idusuariosweb)

	    //MODO EDITAR
	    if ( ruta !== undefined ){
	        this.nModo=2
	        this.readonly= true

	        this.CargarInfo(this.$route.params.cita)
	        this.modo = 'Editar'
      this.titulo = 'Editar cita'

	     } else {
	     		console.log("nuevo")
	        //VALORES INICIALES ALTA
	        this.nModo = 1
	        this.modo  = 'Nuevo'
      this.titulo = 'Nueva cita'
	    }

  },

  methods:{
    ...mapActions('citas',['addCita']),
    ...mapActions("snackbar", ["showSnack"]),
    ...mapActions('contactos',['setContacto','setLimpiarContacto']),
    ...mapActions('clientes',['setCliente','setLimpiarCliente']),


    validate () {
      return this.$refs.form.validate()
    },


    CargarInfo(cita){
      console.log("cita", cita)

      this.asunto= cita.asunto
      this.temas = 	cita.temas
      this.lugar =	cita.lugar

      this.idcontacto=  cita.idcontacto
      this.idproy= 0
      this.idoportunidad= 0
      this.idcita = cita.idcita

      this.fechastart= cita.fechastart
      this.fechaend= cita.fechaend
					
      this.fecha = cita.fechastart
      this.date = cita.fechastart

      this.seledura=  cita.seledura
      this.numproy= cita.numproy

      this.empresa=  cita.empresa
      this.time = cita.hora

      let contacto = {
        nomcont:    cita.nomcont,
        idcontacto: cita.idcontacto,
        empresa: cita.empresa,
        telefono: cita.telefono,
        numcli: "",
        email: cita.email,
        celular: cita.celular,
        sucursal: "",
        departamento: ""
      }
      this.setContacto( contacto)
    },

    agregar(){
				
      //VALIDAR INFORMACION
		    if(!this.validaInfo){
  		    this.showSnack({
          text: "Debes registrar el asunto, los temas y la fecha",
          color: "orange",
          timeout: 3000,
        });

		      return
		    }

      console.log("fecha",this.fecha)
      console.log("idcontacto", this.idcontacto)


      this.fechaend = this.fecha + ' '+ this.time
      this.fechastart = this.fecha + ' '+ this.time

      let payload = {
        contactos: {
          nomcont:    this.getContacto.nomcont,
          empresa:    this.empresa,
          telefono:   this.telefono,
          celular:    this.celular,
          email:      this.email,
          idweb:      this.getCliente.idweb
        },
        citas: {
          asunto: this.asunto,
          temas:  this.temas,
          lugar:  this.lugar,

          fechastart: this.fechastart,
          fechaend: this.fechaend,
          seledura:  this.seledura,
          numproy:  this.numproy,

          idproy: 0,
          idoportunidad: 0,
          idusuariosweb: this.idusuariosweb,
          idcontacto: this.idcontacto,
        }
        // leecliente:  this.leecliente,
      }

      // En caso de de un contacto ya exista.
      // se debe mandar llamar a la api de actualizar contacto....

      if (this.getContacto.idcontacto != ''){
        payload.contactos.idcontacto = this.idcontacto
      }

      console.log("payload", payload)

      this.loading= true
	     //MODO NUEVO
      if (this.nModo == 1) {
        //Dedinir db name
        this.$http.post('v2/insert.cita', payload).then(response =>{
			        this.showSnack({
		            text: "Cita Agregada correctamente",
		            color: "success",
		            timeout: 2000,
		          });

			        this.limpiarCita()
	            setTimeout (() => this.$router.push({name:'catcitas'}),2000);
        }).catch(error=>{
            	// this.snackbar = true ;
	            // this.color="error"
	            // this.text = "Problemas para grabar el cliente"
	            console.log(error)
         			this.showSnack({
		            text: "Problema para agendar Cita."+ error,
		            color: "red",
		            timeout: 2000,
		          });
        })
        
      } else {
        //EDITAR
        //cambiar estatus a 1. Creada.
        // Asignar el idcita a editar
        payload.citas.idcita = this.idcita

        // EDICION DE UNA CITA.
        this.$http.put('v2/update.cita', payload).then(response =>{
          console.log("response", response)
          this.showSnack({
		            text: "Cita Actualizada correctamente.",
		            color: "green",
		            timeout: 2000,
		          });
          setTimeout (() => this.$router.push({name:'catcitas'}),2000);

        },error=>{
          // console.log("falla")
          	this.showSnack({
	            text: "Problema para agendar Cita. ",
	            color: "red",
	            timeout: 3000,
	          });
          console.log(error)
        })

      }


    },
    limpiarCita(){
      this.asunto = ''
      this.temas = ''
      this.lugar = ''

      this.idcontacto = 0
      this.idproy= 0
      this.idoportunidad= 0

      this.fechastart = this.fecha
      this.fechaend = this.fecha
					
      this.seledura= '30'
      this.numproy = ''
      this.empresa = ''

      this.setLimpiarContacto()
      this.setLimpiarCliente()

    },
   				
   	regresar(){
      console.log("ruta", this.$route.name)

      // MODO PANEL
      if (this.$route.name == 'panel') {
        if (this.idcita == undefined){
          console.log("cerrar vista modal y limipiar. ")
          // this.$router.push({name:'panel'}).catch (err => {console.log(err)})
           
        } else {
          console.log("Catalogo regresar")
          this.$router.push({name:'catcitas'})
        }
      }

      // MODO CATALOGO
      if (this.$route.name == 'cita' ){
        this.$router.push({name:'catcitas'})
      }

      
    },

    actualizarDatoscontactos() {
      console.log("actualizarDatoscontactos contacto", this.getContacto)

      this.celular = this.getContacto.celular
      this.empresa = this.getContacto.empresa
      this.idcontacto = parseInt(this.getContacto.idcontacto)
      this.email  = this.getContacto.email
      this.numcli = this.getContacto.numcli
      this.numcont = this.getContacto.numcont
      this.nomcont = this.getContacto.nomcont
      
      this.sucursal = this.getContacto.sucursal
      this.telefono = this.getContacto.telefono
      this.departamento = this.getContacto.departamento

    },

    actualizarNomcont(){
      this.nomcont = this.getNewContacto.nomcont
    },



    hora(){
      var f =new Date();
      var hora = f.getHours()
      var minuto = f.getMinutes()
      var segundo = f.getSeconds()
      if(hora < 10){
        hora = "0" + f.getHours()
      }
      if(minuto < 10){
        minuto = "0" + f.getMinutes()
      }
      if(segundo < 10){
        segundo = "0" + f.getSeconds()
      }
        
      // return hora + ":" + minuto + ":" + segundo
      return hora + ":" + minuto
    },
	  
  }
}
</script>