<template>
  <v-container>
    <v-row class="fill-height">
      <v-col cols="12">
        <v-sheet height="50">
          <v-subheader>
            <v-col cols="auto">
              <v-dialog
                transition="dialog-bottom-transition"
                max-width="400"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    dark
                    color="orange"
                    v-bind="attrs"
                    v-on="on"
                  >
                    Cita
                  </v-btn>
                </template>

                <template v-slot:default="">
                  <v-card>
                    <v-card-text>
                      <cita />
                    </v-card-text>

                    <!--  <v-card-actions class="justify-end">
                      <v-btn
                        text
                        @click="dialog.value = false"
                      >Close</v-btn>
                    </v-card-actions> -->
                  </v-card>
                </template>
              </v-dialog>
            </v-col>

            <v-btn
              outlined
              class="mr-4"
              @click="setToday"
            >
              Hoy
            </v-btn>
            <v-btn
              fab
              text
              small
              @click="prev"
            >
              <v-icon small>
                mdi-chevron-left
              </v-icon>
            </v-btn>
            <v-btn
              fab
              text
              small
              @click="next"
            >
              <v-icon small>
                mdi-chevron-right
              </v-icon>
            </v-btn>
            <!-- <v-toolbar-title>{{ title }}</v-toolbar-title> -->

            <v-spacer />
            <v-menu
              bottom
              right
            >
              <template v-slot:activator="{ on }">
                <v-btn
                  outlined
                  v-on="on"
                >
                  <span>{{ typeToLabel[type] }}</span>
                  <v-icon right>
                    mdi-menu-down
                  </v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="type = 'day'">
                  <v-list-item-title>Día</v-list-item-title>
                </v-list-item>
                <v-list-item @click="type = 'week'">
                  <v-list-item-title>Semana</v-list-item-title>
                </v-list-item>
                <v-list-item @click="type = 'month'">
                  <v-list-item-title>Mes</v-list-item-title>
                </v-list-item>
                <v-list-item @click="type = '4day'">
                  <v-list-item-title>4 días</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-subheader>
        </v-sheet>

        <!-- :event-overlap-mode="mode" -->
        <v-sheet height="500">
          <v-calendar
            ref="calendar"
            v-model="value"
            color="primary"
            :events="events"
            :event-ripple="false"
            :event-overlap-threshold="30"
            :event-color="getEventColor"
            :event-margin-bottom="3"
            locale="es"
            dense
            :type="type"
            :weekdays="[1, 2, 3, 4, 5, 6, 0]"
            :short-weekdays="false"
            @mousedown:event="startDrag"
            @mousedown:time="startTime"
            @mousemove:time="mouseMove"
            @mouseup:time="endDrag"
            @mouseleave.native="cancelDrag"
            @click:event="showEvent"
            @click:more="viewDay"
            @click:date="viewDay"
          >
            <template v-slot:day-body="{ date, week }">
              <div
                class="v-current-time"
                :class="{ first: date === week[0].date }"
                :style="{ top: nowY }"
              />
            </template>

            <template v-slot:event="{ event, timed, eventSummary }">
              <div
                class="v-event-draggable"
                v-html="eventSummary()"
              />
              <div
                v-if="timed"
                class="v-event-drag-bottom"
                @mousedown.stop="extendBottom(event)"
              />
            </template>

            <!--  <template v-slot:day-body="{ date, week, timeToY }">
              <div
                class="v-current-time"
                :class="{ first: date === week[0].date }"
                :style="{ top: nowY(timeToY) }"
              ></div>
            </template> -->
          </v-calendar>

          <v-menu
            v-model="selectedOpen"
            :close-on-content-click="false"
            :activator="selectedElement"
            offset-x
          >
            <v-card
              color="grey lighten-4"
              min-width="350px"
              flat
            >
              <v-toolbar
                dense
                :color="selectedEvent.color"
                dark
              >
                <v-btn
                  icon
                  @click="deleteEvent(selectedEvent)"
                >
                  <v-icon>mdi-calendar</v-icon>
                </v-btn>
                <v-toolbar-title v-html="selectedEvent.name" />
                <v-spacer />
              </v-toolbar>

              <v-card-text>
                <v-form v-if="currentlyEditing !== selectedEvent.id">
                  {{ selectedEvent.name }} - {{ selectedEvent.details }}
                </v-form>

                <v-form v-else>
                  <v-text-field
                    v-model="selectedEvent.name"
                    type="text"
                    label="Editar Nombre"
                  />

                  <v-textarea
                    v-model="selectedEvent.details"
                    auto-grow
                    type="text"
                    style="width: 100%"
                    :min-height="100"
                  />
                </v-form>
              </v-card-text>

              <v-card-actions>
                <v-btn
                  text
                  color="secondary"
                  @click="selectedOpen = false"
                >
                  Cancel
                </v-btn>
                <v-btn
                  v-if="currentlyEditing !== selectedEvent.id"
                  text
                  @click.prevent="editEvent(selectedEvent.id)"
                >
                  Editar
                </v-btn>

                <v-btn
                  v-else
                  text
                  @click.prevent="updateEvent(selectedEvent)"
                >
                  Guardar Cambios
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-menu>
        </v-sheet>
      </v-col>
    </v-row>

    <!-- {{ events[0]}} -->

    <!-- event 1 {{ events[0].fechastart }}
    event 2 {{ events[0].fechaend}} -->
    <!-- inicio    {{ events[0].start}}
  fin       {{ events[0].end}} -->
  </v-container>
</template>

<script>
import Cita from '@/views/crm/citas/cita.vue'

export default {
  components: {
    Cita
  },
  data: () => ({
    mostrarventana: false,
    today: new Date().toISOString().substr(0, 10),
    focus: '',
    type: 'week',
    typeToLabel: {
      month: 'Mes',
      week: 'Semana',
      day: 'Day',
      '4day': '4 Days'
    },
    start: null,
    end: null,
    selectedEvent: {},
    selectedElement: null,
    selectedOpen: false,
    mode: 'column',

    events: [],
    name: null,
    details: null,
    colors: [
      'blue',
      'indigo',
      'deep-purple',
      'cyan',
      'green',
      'orange',
      'grey darken-1'
    ],
    color: 'primary',
    dialog: false,
    currentlyEditing: null,

    //Linea Roja
    value: '',
    ready: false,

    dragEvent: null,
    dragStart: null,
    createEvent: null,
    createStart: null,
    extendOriginal: null
  }),

  computed: {
    cal() {
      return this.ready ? this.$refs.calendar : null
    },

    nowY() {
      return this.cal ? this.cal.timeToY(this.cal.times.now) + 'px' : '-10px'
    },

    title() {
      return ''
      // const { start, end } = this
      // if (!start || !end) {
      //   return ''
      // }

      // const startMonth = this.monthFormatter(start)
      // const endMonth = this.monthFormatter(end)
      // const suffixMonth = startMonth === endMonth ? '' : endMonth

      // const startYear = start.year
      // const endYear = end.year
      // const suffixYear = startYear === endYear ? '' : endYear

      // const startDay = start.day + this.start.day
      // const endDay = end.day + this.end.day

      // switch (this.type) {
      //   case 'month':
      //     return `${startMonth} ${startYear}`
      //   case 'week':

      //   case '4day':
      //     return `${startMonth} ${startDay} ${startYear} - ${suffixMonth} ${endDay} ${suffixYear}`
      //   case 'day':
      //     return `${startMonth} ${startDay} ${startYear}`
      // }
      // return ''
    },

    monthFormatter() {
      return ''
      // return this.$refs.calendar.getFormatter({
      //   timeZone: 'UTC', month: 'long',
      // })
    }
  },

  mounted() {
    this.ready = true
    this.scrollToTime()
    this.updateTime()

    this.$refs.calendar.scrollToTime('08:00')
    this.$refs.calendar.checkChange()
  },

  created() {
    this.getEvents()
  },

  methods: {
    startDrag({ event, timed }) {
      console.log('start Drag event', event)
      console.log('start Drag timed', timed)

      if (event && timed) {
        this.dragEvent = event
        this.dragTime = null
        this.extendOriginal = null
      }
    },
    startTime(tms) {
      const mouse = this.toTime(tms)
      console.log('starTime mouse ', mouse)

      if (this.dragEvent && this.dragTime === null) {
        const start = this.dragEvent.start

        console.log('starTime start ', start)
        this.dragTime = mouse - start
      } else {
        this.createStart = this.roundTime(mouse)

        this.createEvent = {
          name: `Event #${this.events.length}`,
          color: this.rndElement(this.colors),
          start: this.createStart,
          end: this.createStart,
          timed: true
        }
      }
    },

    extendBottom(event) {
      this.createEvent = event
      this.createStart = event.start
      this.extendOriginal = event.end
    },

    mouseMove(tms) {
      const mouse = this.toTime(tms)

      if (this.dragEvent && this.dragTime !== null) {
        console.log('mouseMove', mouse)
        console.log('this.dragEvent', this.dragEvent)

        const start = this.dragEvent.start
        const end = this.dragEvent.end
        const duration = end - start
        const newStartTime = mouse - this.dragTime
        const newStart = this.roundTime(newStartTime)
        const newEnd = newStart + duration

        this.dragEvent.start = newStart
        this.dragEvent.end = newEnd
      } else if (this.createEvent && this.createStart !== null) {
        const mouseRounded = this.roundTime(mouse, false)
        const min = Math.min(mouseRounded, this.createStart)
        const max = Math.max(mouseRounded, this.createStart)

        this.createEvent.start = min
        this.createEvent.end = max
      }
    },

    endDrag() {
      this.dragTime = null
      this.dragEvent = null
      this.createEvent = null
      this.createStart = null
      this.extendOriginal = null
    },
    cancelDrag() {
      if (this.createEvent) {
        if (this.extendOriginal) {
          this.createEvent.end = this.extendOriginal
        } else {
          const i = this.events.indexOf(this.createEvent)
          if (i !== -1) {
            this.events.splice(i, 1)
          }
        }
      }

      this.createEvent = null
      this.createStart = null
      this.dragTime = null
      this.dragEvent = null
    },
    roundTime(time, down = true) {
      const roundTo = 15 // minutes
      const roundDownTime = roundTo * 60 * 1000

      return down
        ? time - (time % roundDownTime)
        : time + (roundDownTime - (time % roundDownTime))
    },
    toTime(tms) {
      return new Date(
        tms.year,
        tms.month - 1,
        tms.day,
        tms.hour,
        tms.minute
      ).getTime()
    },

    // nowY (timeToY) {
    //   return this.cal ? timeToY(this.cal.times.now) + 'px' : '-10px'
    // },

    getCurrentTime() {
      // console.log('calendar', this.cal)
      return this.cal
        ? this.cal.times.now.hour * 60 + this.cal.times.now.minute
        : 0
    },

    scrollToTime() {
      const time = this.getCurrentTime()
      // console.log('time', time)
      const first = Math.max(0, time - (time % 30) - 30)
      // console.log('first', first)
      this.cal.scrollToTime(first)
    },

    updateTime() {
      // console.log('updateTime', this.cal)
      setInterval(() => this.cal.updateTimes(), 60 * 1000)
    },

    mostrar() {
      this.mostrarventana = true
    },

    //async updateEvent(ev){
    //  try {
    //
    //    await db.collection('eventos').doc(ev.id).update({
    //      name: ev.name,
    //      details: ev.details
    //    })
    //
    //    this.selectedOpen = false;
    //    this.currentlyEditing = null;
    //
    //
    //  } catch (error) {
    //    console.log(error);
    //  }
    //},

    editEvent(id) {
      this.currentlyEditing = id
    },

    //async deleteEvent(ev){
    //  try {
    //
    //    await db.collection('eventos').doc(ev.id).delete();
    //    this.selectedOpen = false;
    //    this.getEvents();
    //
    //  } catch (error) {
    //    console.log(error);
    //  }
    //},

    async addEvent() {
      try {
        if (this.name && this.start && this.end) {
          await db.collection('eventos').add({
            name: this.name,
            details: this.details,
            start: this.start,
            end: this.end,
            color: this.color
          })

          this.getEvents()

          this.name = null
          this.details = null
          this.start = null
          this.end = null
          this.color = '#1976D2'
        } else {
          console.log('Campos obligatorios')
        }
      } catch (error) {
        console.log(error)
      }
    },

    // async getEvents(){
    //   try {

    //     const snapshot = await db.collection('eventos').get();
    //     const events = [];

    //     snapshot.forEach(doc => {
    //       // console.log(doc.id);
    //       let eventoData = doc.data();
    //       eventoData.id = doc.id;
    //       events.push(eventoData);
    //     })

    //     this.events = events;

    //   } catch (error) {
    //     console.log(error);
    //   }

    // },

    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a
    },

    async getEvents() {
      try {
        //CARGAR CITAS
        this.$http.get('v2/citas.list').then(response => {
          console.log('response CITAS ', response)
          if (response.data.error === null) {
            // this.events = response.data.result

            response.data.result.forEach(element => {
              element.name = element.asunto
              element.details = element.temas

              let start2 = new Date(element.fechastart + ':00').getTime()
              let end2 = new Date(element.fechaend + ':00').getTime()
              element.timed = true
              element.start = start2
              element.end = end2
              // element.color = this.colors[this.rnd(0, this.colors.length - 1)]
              element.color = 'blue darken-3'
              this.events.push(element)
            })
            console.log('citas  offset', this.events)
          }
        })
      } catch (error) {
        console.log(error)
      }

      // CARGAR ACTIVIDADES
      // this.$http
      //   .get('v2/actividades.list')
      //   .then(response => {
      //     console.log('response actividades')
      //     if (response.data.error === null) {
      //       // this.events = response.data.result

      //       response.data.result.forEach(element => {
      //         element.name = element.tipo
      //         element.details = element.obs
      //         element.start = element.fecha
      //         element.end = element.fechafin
      //         // element.color = this.colors[this.rnd(0, this.colors.length - 1)]
      //         element.color = 'red'
      //         this.events.push(element)
      //       })
      //       console.log('eventos actividades', this.events)
      //     }
      //   })
      //   .catch(error => {
      //     console.log(error)
      //   })
    },

    viewDay({ date }) {
      this.focus = date
      this.type = 'day'
    },

    getEventColor(event) {
      return event.color
    },

    setToday() {
      this.focus = this.today
    },

    prev() {
      this.$refs.calendar.prev()
    },
    next() {
      this.$refs.calendar.next()
    },

    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event
        this.selectedElement = nativeEvent.target
        setTimeout(() => (this.selectedOpen = true), 10)
      }

      if (this.selectedOpen) {
        this.selectedOpen = false
        setTimeout(open, 10)
      } else {
        open()
      }

      nativeEvent.stopPropagation()
    },

    updateRange({ start, end }) {
      // You could load events from an outside source (like database) now that we have the start and end dates on the calendar
      this.start = start
      this.end = end
    },

    nth(d) {
      return d > 3 && d < 21
        ? 'th'
        : ['th', 'st', 'nd', 'rd', 'th', 'th', 'th', 'th', 'th', 'th'][d % 10]
    },
    rndElement(arr) {
      return arr[this.rnd(0, arr.length - 1)]
    }
  }
}
</script>

<style lang="scss">
.v-current-time {
  height: 2px;
  background-color: #ea4335;
  position: absolute;
  left: -1px;
  right: 0;
  pointer-events: none;

  &.first::before {
    content: '';
    position: absolute;
    background-color: #ea4335;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-top: -5px;
    margin-left: -6.5px;
  }

  .v-event-draggable {
    padding-left: 6px;
  }

  .v-event-timed {
    user-select: none;
    -webkit-user-select: none;
  }

  .v-event-drag-bottom {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 4px;
    height: 4px;
    cursor: ns-resize;

    &::after {
      display: none;
      position: absolute;
      left: 50%;
      height: 4px;
      border-top: 1px solid white;
      border-bottom: 1px solid white;
      width: 16px;
      margin-left: -8px;
      opacity: 0.8;
      content: '';
    }

    &:hover::after {
      display: block;
    }
  }
}
</style>
