<template>
  <v-container class="ma-0 pa-0">
    <v-row class="ma-0 pa-0">
      <v-col class="ma-1 pa-1" cols="12" xm="12">
        <v-text-field
          v-model="buscando"
          class="mx-3 pb-5"
          append-icon="mdi-check-circle"
          label="Buscar cliente y dar enter"
          prepend-icon="mdi-magnify"
          clearable
          :loading="loading"
          hide-details
          @click:append="buscarCliente"
          @keyup.enter="buscarCliente"
          @click:clear="limpiar"
          @keyup.113="buscarCliente"
        />
        <h4 v-if="idweb !== ''" class="mx-5 my-0">id: {{ idweb }}</h4>
      </v-col>
    </v-row>

    <v-row class="ma-0 pa-0">
      <v-col class="ma-0 pa-0" cols="12" xm="12">
        <p v-if="clientes.length > 0" class="ma-1">
          {{ clientes.length }} resultados
        </p>

        <v-data-table
          v-if="clientes.length > 0"
          ref="tabla"
          class="grey lighten-2"
          :headers="headers"
          :items="clientes"
          item-key="idweb"
          :items-per-page="longitud"
          fixed-header
          dense
          :loading="loading"
          hide-default-header
          hide-default-footer
          height="300"
          @click:row="seleccionar"
        >
          <template v-slot:item="{ item }">
            <tr @click.once="seleccionar(item)">
              <td>{{ item.idweb }}</td>
              <td>{{ item.nomcli }}</td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  data: () => ({
    buscando: '',
    longitud: 30,
    loading: false,

    clientes: [],

    nomcli: '',
    idweb: '',
    //SNACKBAR
    text: '',
    snackbar: false,
    color: '',

    headers: [
      { text: 'Id', align: 'start', value: 'idweb' },
      { text: 'Cliente', align: 'end', value: 'nomcli' }
    ]
  }),

  computed: {
    ...mapGetters('clientes', ['getCliente'])
  },

  watch: {
    getCliente() {
      // this.limpiar()
      this.actualizarLeeclientes()
    }
  },

  created() {
    this.buscando = ''
    this.idweb = ''

    if (this.getCliente.idweb == undefined) {
      this.buscando = ''
      this.idweb = ''
    } else {
      this.buscando = this.getCliente.nomcli
      this.idweb = this.getCliente.idweb
    }
  },

  methods: {
    ...mapActions('clientes', [
      'setCliente',
      'setNewCliente',
      'limpiarcliente'
    ]),
    ...mapActions('snackbar', ['showSnack']),

    actualizarLeeclientes() {
      this.buscando = this.getCliente.nomcli
      this.nomcli = this.getCliente.nomcli
      this.idweb = this.getCliente.idweb
    },

    buscarCliente() {
      //ELIMINANDO BUSQUEDAS VACIAS
      if (this.buscando == null) return

      var payload = {
        Buscacli: this.buscando.trim()
      }

      this.loading = true
      this.$http
        .post('v2/clientes.find', payload)
        .then(response => {
          this.loading = false

          if (response.data.Total > 0) {
            this.clientes = response.data.Data
            this.longitud = response.data.Total
          } else {
            this.clientes = []
            this.showSnack({
              text: 'No se encontraron clientes',
              color: 'info',
              timeout: 2000
            })
          }
        })
        .catch(error => {
          this.clientes = []
          this.loading = false
          // console.log()
        })
    },

    limpiar() {
      this.clientes = []
      this.idweb = ''
      this.nomcli = ''
      this.buscando = ''
      let cliente = {
        nomcli: '',
        idweb: '',
        numcli: ''
      }
      var item = {}
      this.setCliente(item)
    },

    seleccionar(item) {
      // console.log("item", item)
      this.nomcli = item.nomcli
      this.idweb = item.idweb

      this.buscando = item.nomcli
      this.clientes = []
      this.setCliente(item)
    }
  }
}
</script>
